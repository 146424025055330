$sans-serif: 'NotoSans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
$serif: 'PlayfairDisplay', Georgia, 'Times New Roman', Times, serif;

body {
    font-family: $sans-serif;
    font-weight: normal;
}

h1 {
    font-family: $serif;
    font-weight: 700;
}

h2 {
    font-family: $serif;
    font-weight: 700;
}

h3 {
    font-family: $sans-serif;
}