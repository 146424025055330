.characterCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}


.characterCardBox {
    position: relative;
    text-align: left;
    width: 220px;
    height: 250px; /*change*/
    border-radius: $radius;
    background-color: white;
    padding: $padding-content;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    p {
        color: black;
        line-height: 22px;
    }

    button {
        background-color: white;
        padding: 4px 8px;
        font-weight: bold;
        border-width: 1px;
        border-style: solid;
    }

    button:hover {
        background-color: white;
        outline-color: rgba(255, 255, 255, 0);
        outline-offset: 8px;
    }
}

.characterCardHeader{
    display: flex;
    align-items: center;
    gap: 12px;


    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 36px;
        height: 36px;
        border-radius: $radius;

        img {
            width: 24px;
            height: 24px;
            filter: invert(100%);
        }
    }

    h1 {
        font-family: $sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
        margin: 0;
        margin-top: 4px;
    }
}

.career {
    & > img {
        width: 160px;
        margin-bottom: -12px;
        z-index: 2;
    }

    .characterCardBox {
        border: 4px solid $color-career-secondary;
    }

    .characterCardBackground {
        position: absolute;
        z-index: -1;
        top: 20%;
        left: 30%;
        transform: translate(-50%, -50%);
        display: block;
        width: 600px;
        height: 420px;
        border-radius: 100%;
        background: radial-gradient(ellipse at center, rgba(255,201,63,0.5) 0%, rgba(48,66,233,0) 68%);
    }

    .characterCardHeader > div {
        background-color: $color-career-primary;
    }

    .characterCardHeader > h1 {
        color: black;
    }

    button {
        color: $color-career-primary;
        outline-color: white;
        border-color: $color-career-secondary;
        border-width: 2px;
    }

    button:hover {
        background-color: $color-career-secondary;
        border-color: $color-career-primary;
    }
}

.social {
    & > img {
        margin-left: 24px;
        margin-bottom: 24px;
        width: 200px;
    }

    .characterCardBox {
        border: 4px solid $color-social-secondary;
    }

    .characterCardBackground {
        position: absolute;
        z-index: -2;
        top: 70%;
        left: 30%;
        transform: translate(-50%, -50%);
        display: block;
        width: 300px;
        height: 250px;
        border-radius: 100%;
        background: radial-gradient(ellipse at center, rgba(248,166,255,0.5) 0%, rgba(48,66,233,0) 70%);
    }

    .characterCardHeader > div {
        background-color: $color-social-primary;
    }

    .characterCardHeader > h1 {
        color: black;
    }

    button {
        color: $color-social-primary;
        border-color: $color-social-secondary;
        outline-color: white;
        border-width: 2px;
    }

    button:hover {
        background-color: $color-social-secondary;
        border-color: $color-social-primary;
    }
}

.academic {
    img {
        width: 150px;
    }

    .characterCardBox {
        border: 4px solid $color-academic-secondary;
    }

    .characterCardBackground {
        position: absolute;
        z-index: -1;
        top: 30%;
        left: 20%;
        transform: translate(-50%, -50%);
        display: block;
        width: 400px;
        height: 330px;
        border-radius: 100%;
        background: radial-gradient(ellipse at center, rgba(112, 124, 232, 0.8) 0%, rgba(48,66,233,0) 70%);
    }

    .characterCardHeader > div {
        background-color: $color-academic-primary;
    }

    .characterCardHeader > h1 {
        color: black;
    }


    button {
        color: $color-academic-primary;
        border-color: $color-academic-secondary;
        outline-color: white;
        border-width: 2px;
    }

    button:hover {
        background-color: $color-academic-secondary;
        border-color: $color-academic-primary;
    }
}

@include media(">=sm-desktop", "<desktop") {
    .career {
        .characterCardBackground {
            position: absolute;
            z-index: -1;
            top: 20%;
            left: 30%;
            transform: translate(-50%, -50%);
            display: block;
            width: 500px;
            height: 380px;
            border-radius: 100%;
            background: radial-gradient(ellipse at center, rgba(255,201,63,0.5) 0%, rgba(48,66,233,0) 68%);
        }
    }

    .social {
        .characterCardBackground {
            position: absolute;
            z-index: -2;
            top: 70%;
            left: 30%;
            transform: translate(-50%, -50%);
            display: block;
            width: 300px;
            height: 250px;
            border-radius: 100%;
            background: radial-gradient(ellipse at center, rgba(248,166,255,0.5) 0%, rgba(48,66,233,0) 70%);
        }
    }

    .academic {
        .characterCardBackground {
            position: absolute;
            z-index: -1;
            top: 30%;
            left: 44%;
            transform: translate(-50%, -50%);
            display: block;
            width: 380px;
            height: 300px;
            border-radius: 100%;
            background: radial-gradient(ellipse at center, rgba(112, 124, 232, 0.8) 0%, rgba(48,66,233,0) 70%);
        }
    }
}


@include media("screen", "<tablet") {
    .characterCard {
        scroll-snap-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }


    .characterCardBox {
        position: relative;
        text-align: left;
        width: 220px;
        height: 250px; /*change*/
        border-radius: $radius;
        background-color: white;
        padding: $padding-content;
        box-sizing: border-box;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

        p {
            color: black;
            line-height: 22px;
        }

        button {
            background-color: white;
            padding: 4px 8px;
            font-weight: bold;
            border-width: 1px;
            border-style: solid;
        }

        button:hover {
            background-color: white;
            outline-color: rgba(255, 255, 255, 0);
            outline-offset: 8px;
        }
    }

    .characterCardHeader{
        display: flex;
        align-items: center;
        gap: 12px;


        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            width: 36px;
            height: 36px;
            border-radius: $radius;

            img {
                width: 24px;
                height: 24px;
                filter: invert(100%);
            }
        }

        h1 {
            font-family: $sans-serif;
            font-weight: bold;
            font-size: 1.2rem;
            margin: 0;
            margin-top: 4px;
        }
    }

    .career {
        & > img {
            width: 160px;
            margin-bottom: -12px;
            z-index: 2;
        }

        .characterCardBox {
            border: 4px solid $color-career-secondary;
        }

        .characterCardBackground {
            position: absolute;
            z-index: -1;
            top: 20%;
            left: 30%;
            transform: translate(-50%, -50%);
            display: block;
            width: 600px;
            height: 420px;
            border-radius: 100%;
            background: radial-gradient(ellipse at center, rgba(255,201,63,0.5) 0%, rgba(48,66,233,0) 68%);
        }

        .characterCardHeader > div {
            background-color: $color-career-primary;
        }

        .characterCardHeader > h1 {
            color: black;
        }

        button {
            color: $color-career-primary;
            outline-color: white;
            border-color: $color-career-secondary;
            border-width: 2px;
        }

        button:hover {
            background-color: $color-career-secondary;
            border-color: $color-career-primary;
        }
    }

    .social {
        & > img {
            margin-left: 24px;
            margin-bottom: 24px;
            width: 200px;
        }

        .characterCardBox {
            border: 4px solid $color-social-secondary;
        }

        .characterCardBackground {
            position: absolute;
            z-index: -2;
            top: 70%;
            left: 30%;
            transform: translate(-50%, -50%);
            display: block;
            width: 300px;
            height: 250px;
            border-radius: 100%;
            background: radial-gradient(ellipse at center, rgba(248,166,255,0.5) 0%, rgba(48,66,233,0) 70%);
        }

        .characterCardHeader > div {
            background-color: $color-social-primary;
        }

        .characterCardHeader > h1 {
            color: black;
        }

        button {
            color: $color-social-primary;
            border-color: $color-social-secondary;
            outline-color: white;
            border-width: 2px;
        }

        button:hover {
            background-color: $color-social-secondary;
            border-color: $color-social-primary;
        }
    }

    .academic {
        img {
            width: 150px;
        }

        .characterCardBox {
            border: 4px solid $color-academic-secondary;
        }

        .characterCardBackground {
            position: absolute;
            z-index: -1;
            top: 30%;
            left: 20%;
            transform: translate(-50%, -50%);
            display: block;
            width: 400px;
            height: 330px;
            border-radius: 100%;
            background: radial-gradient(ellipse at center, rgba(112, 124, 232, 0.8) 0%, rgba(48,66,233,0) 70%);
        }

        .characterCardHeader > div {
            background-color: $color-academic-primary;
        }

        .characterCardHeader > h1 {
            color: black;
        }


        button {
            color: $color-academic-primary;
            border-color: $color-academic-secondary;
            outline-color: white;
            border-width: 2px;
        }

        button:hover {
            background-color: $color-academic-secondary;
            border-color: $color-academic-primary;
        }
    }
}